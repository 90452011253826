// components/LandingPage.js
import React from 'react';
import { Typography, Button, Container } from '@material-ui/core';
import { config } from '../config';

export default function LandingPage() {
  const handleGoogleLogin = () => {
    const backendURL = process.env.REACT_APP_API_URL || 'https://it-guy.seo-top.dev/api';
    // Remove /api prefix since it's handled by the gateway
    window.location.href = `${backendURL}/auth/google`;
  };

  return (
    <Container maxWidth="md" style={{ marginTop: '2rem' }}>
      <Typography variant="h2" gutterBottom>
        Welcome to IT Support Platform
      </Typography>
      <Typography variant="body1" paragraph>
        Get instant IT support or offer your expertise to others.
      </Typography>
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleGoogleLogin}
      >
        Login with Google
      </Button>
    </Container>
  );
}