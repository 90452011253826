// FrontEnd/src/components/AuthCallback.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { CircularProgress } from '@material-ui/core';
import { getDashboardRoute } from '../utils/routeHelpers';
import { tokenManager } from '../services/TokenManager';
import api from '../services/api';

export default function AuthCallback() {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    const handleAuth = async () => {
      if (isProcessing) {
        console.log('Already processing auth callback');
        return;
      }
      
      console.log('Starting auth callback processing');
      
      try {
        setIsProcessing(true);
        
        console.log('Verifying user session...');
        const response = await api.get('/api/auth/current-user');
        console.log('User verification response:', response.data);

        if (!response.data?.user) {
          throw new Error('No user data received');
        }

        console.log('Logging in user:', response.data.user);
        const userData = await login(response.data.user);
        console.log('Login successful, user:', userData);

        const redirectPath = getDashboardRoute(userData);
        console.log('Redirecting to:', redirectPath);
        
        navigate(redirectPath, { replace: true });
      } catch (error) {
        console.error('Authentication error:', error);
        console.error('Error stack:', error.stack);
        console.error('Error details:', {
          message: error.message,
          response: error.response?.data,
          status: error.response?.status
        });
        
        setError(error.message);
        tokenManager.clearSilent();
        navigate('/login', { replace: true, state: { error: error.message } });
      } finally {
        setIsProcessing(false);
      }
    };
  
    handleAuth();
  }, [login, navigate, isProcessing]);

  if (error) {
    return (
      <div style={{ textAlign: 'center', marginTop: '2rem' }}>
        <h2>Authentication Error</h2>
        <p>{error}</p>
        <pre style={{ textAlign: 'left', margin: '1rem' }}>
          {JSON.stringify(error, null, 2)}
        </pre>
      </div>
    );
  }

  return (
    <div style={{ textAlign: 'center', marginTop: '2rem' }}>
      <h2>Authenticating...</h2>
      <p>Please wait while we complete your authentication...</p>
      <CircularProgress />
    </div>
  );
}