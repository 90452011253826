// FrontEnd/src/AppRoutes.js
import React, { Suspense, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import ErrorBoundary from './ErrorBoundary';
// Import components
import UserDashboard from './components/UserDashboard';
import ServiceWorkerDashboard from './components/ServiceWorkerDashboard';
import AdminDashboard from './components/AdminDashboard';
import Onboarding from './components/Onboarding';
import TicketDetails from './components/TicketDetails';
import LandingPage from './components/LandingPage';
import IssueSelectionPage from './components/IssueSelectionPage';
import CreateTicketPage from './components/CreateTicketPage';
import RemoteSessionWrapper from './components/RemoteSession/RemoteSessionWrapper';
import AuthCallback from './components/AuthCallback';
import ErrorFallback from './components/ErrorFallback';
import ProtectedRoute from './components/ProtectedRoute';
import { LoadingSpinner } from './components/common/LoadingSpinner';
import { getDashboardRoute } from './utils/routeHelpers';

function AppRoutes() {
  const { user, loading, isAuthenticated, isInitialized } = useAuth();
  const location = useLocation();
  const isPublicRoute = ['/', '/login', '/auth/callback'].includes(location.pathname);

  // Show loading state while auth is initializing
  if (!isInitialized || loading) {
    return <LoadingSpinner message="Checking authentication..." />;
  }
  
  console.log('AppRoutes - Auth State:', { 
    user, 
    loading, 
    isAuthenticated, 
    currentPath: location.pathname 
  });

  // Allow public routes even when not authenticated
  if (!isAuthenticated && !isPublicRoute) {
    return <Navigate to="/" replace />;
  }
  
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback={<LoadingSpinner message="Checking authentication..." />}>
        <Routes>
          <Route path="/auth/callback" element={<AuthCallback />} />
          
          <Route
            path="/dashboard"
            element={
              user ? (
                <Navigate to={getDashboardRoute(user)} replace />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />

          {/* Landing Page */}
          <Route
            path="/"
            element={<LandingPage />}
          />

          {/* Protected Routes */}
          <Route
            path="/admin/dashboard"
            element={
              <ProtectedRoute allowedRoles={['admin']}>
                <AdminDashboard />
              </ProtectedRoute>
            }
          />

          <Route
            path="/service-worker-dashboard/*"
            element={
              <ProtectedRoute allowedRoles={['service_worker', 'admin']}>
                <ServiceWorkerDashboard />
              </ProtectedRoute>
            }
          />

          <Route
            path="/user-dashboard/*"
            element={
              <ProtectedRoute allowedRoles={['user']}>
                <UserDashboard />
              </ProtectedRoute>
            }
          />

          <Route
            path="/onboarding"
            element={
              user && (!user.role || user.role === 'new') ? (
                <Onboarding />
              ) : (
                <Navigate to={getDashboardRoute(user)} />
              )
            }
          />

          {/* Feature Routes - Protected based on role */}
          <Route
            path="/issue-selection"
            element={
              <ProtectedRoute allowedRoles={['user', 'admin']}>
                <IssueSelectionPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/create-ticket"
            element={
              <ProtectedRoute allowedRoles={['user', 'admin']}>
                <CreateTicketPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/tickets/:id"
            element={
              <ProtectedRoute allowedRoles={['user', 'service_worker', 'admin']}>
                <TicketDetails />
              </ProtectedRoute>
            }
          />

          <Route
            path="/remote-session/:roomId"
            element={
              <ProtectedRoute>
                <ErrorBoundary>
                  <RemoteSessionWrapper />
                </ErrorBoundary>
              </ProtectedRoute>
            }
          />

          {/* Catch-all route */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
}

export default AppRoutes;