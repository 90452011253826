// components/ProtectedRoute.js
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { tokenManager } from '../services/TokenManager';
import { LoadingSpinner } from './common/LoadingSpinner';

export default function ProtectedRoute({ children, allowedRoles = [] }) {
  const location = useLocation();
  const { isAuthenticated, authLoading, currentUser } = useAuth();

  // Show loading screen while checking authentication
  if (authLoading) {
    return <LoadingSpinner message="Checking authentication..." />;
  }

  // If not authenticated, redirect to login
  if (!isAuthenticated) {
    // Store the attempted URL for redirect after login
    sessionStorage.setItem('redirectUrl', location.pathname);
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Check if token is valid
  if (!tokenManager.isAuthenticated()) {
    tokenManager.clearSilent();
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // If role is required, check user role
  if (allowedRoles.length > 0 && !allowedRoles.includes(currentUser?.role)) {
    return <Navigate to="/unauthorized" replace />;
  }

  // If all checks pass, render the protected content
  return children;
}